































































































import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";

import { Business, Employee, EmployeePermission, Role } from "@/types";

import employeePermissionStoreModule from "@/store/modules/employeePermission";
import pgStoreModule from "@/store/modules/permissionGroup";

const { mapActions: permissionActions, mapGetters: permissionGetters } =
  createNamespacedHelpers("EMPLOYEE_PERMISSIONS");

const { mapActions: pgActions, mapGetters: pgGetters } =
  createNamespacedHelpers("PERMISSION_GROUP");
export default Vue.extend<any, any, any, any>({
  name: "EmployeePermissionGroupList",
  props: {
    employee: {
      type: Object as PropType<Employee>,
      required: true,
    },
  },
  data: () => ({
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Role", value: "role" },
      { text: "Action", value: "action" },
    ],
    permission: undefined as undefined | EmployeePermission,
    showDeleteDialog: false,
    options: {} as { page: number },
    showAddDialog: false,
    permissionGroupId: "",
  }),
  watch: {
    employee() {
      if (this.employee) {
        this.fetchPermissions();
      }
    },
    options: {
      handler() {
        this.fetchPermissions();
      },
      deep: true,
    },
    role: "fetchData",
  },
  mounted() {
    this.fetchPermissions();
  },
  created() {
    this.fetchData();
  },
  computed: {
    ...permissionGetters(["employeePermissionPage"]),
    ...pgGetters(["permissionGroupPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...pgActions(["fetchPermissionGroupList"]),
    ...permissionActions([
      "fetchEmployeePermission",
      "deleteEmployeePermission",
      "createEmployeePermission",
    ]),
    fetchPermissions() {
      this.fetchEmployeePermission(
        `?employeeId=${this.employee._id}&page=${
          this.options.page || 1
        }&limit=10`
      );
    },
    fetchData() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        this.fetchPermissionGroupList(`?businessId=${bid}`);
      }
    },
    save() {
      this.createEmployeePermission({
        employeeId: this.employee._id,
        accessType: "read",
        permissionGroupId: this.permissionGroupId,
      }).then((ep) => {
        if (ep) this.showAddDialog = false;
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("EMPLOYEE_PERMISSIONS")) {
      this.$store.registerModule(
        "EMPLOYEE_PERMISSIONS",
        employeePermissionStoreModule
      );
    }
    if (!this.$store.hasModule("PERMISSION_GROUP")) {
      this.$store.registerModule("PERMISSION_GROUP", pgStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("EMPLOYEE_PERMISSIONS");
    this.$store.unregisterModule("PERMISSION_GROUP");
  },
});
