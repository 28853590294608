






import Vue from "vue";

import EmployeeForm from "@/components/employee/EmployeeForm.vue";
export default Vue.extend<any, any, any, any>({
  components: { EmployeeForm },
  name: "Employee",
  props: {
    employeeId: {
      type: String,
      required: true,
    },
  },
});
