import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { PermissionGroup, Page } from "@/types";

type PermissionGroupState = {
  permissionGroupPage: Page<PermissionGroup>;
};

const permissionGroup: Module<PermissionGroupState, unknown> = {
  namespaced: true,
  state: () => ({
    permissionGroupPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getPermissionGroup: (state) => (permissionGroupId: string) =>
      state.permissionGroupPage.docs.find((pg) => pg._id === permissionGroupId),
    permissionGroupPage: (state) => state.permissionGroupPage,
  },
  mutations: {
    ADD_PERMISSION_GROUP: (state, _permissionGroup) => {
      let idx = -1;
      state.permissionGroupPage.docs.map((pg, i) => {
        if (pg._id === _permissionGroup._id) idx = i;
      });
      if (idx === -1) state.permissionGroupPage.docs.push(_permissionGroup);
      else Vue.set(state.permissionGroupPage.docs, idx, _permissionGroup);
    },
    SET_PERMISSION_GROUP_PAGE: (state, list) => {
      state.permissionGroupPage = list;
    },
    REMOVE_PERMISSION_GROUP(state, permissionGroup) {
      let idx = -1;
      state.permissionGroupPage.docs.map((pg, i) => {
        if (pg._id === permissionGroup._id) idx = i;
      });
      if (idx > -1) state.permissionGroupPage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchPermissionGroup(context, params = "") {
      api
        .get(`/v1/permission-group${params}`, {
          headers: { "vendor-module": "employees.Permissions" },
        })
        .then((response) => {
          context.commit("ADD_PERMISSION_GROUP", response.data.permissionGroup);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchPermissionGroupList(context, params = "") {
      return await api
        .get(`/v1/permission-group${params}`, {
          headers: { "vendor-module": "employees.Permissions" },
        })
        .then((response) => {
          context.commit(
            "SET_PERMISSION_GROUP_PAGE",
            response.data.permissionGroupPage
          );
          return response.data.permissionGroupPage;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createPermissionGroup(context, payload) {
      return await api
        .post(`/v1/permission-group`, payload, {
          headers: { "vendor-module": "employees.Permissions" },
        })
        .then((response) => {
          context.commit("ADD_PERMISSION_GROUP", response.data.permissionGroup);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "PermissionGroup created",
            },
            { root: true }
          );
          return response.data.permissionGroup;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updatePermissionGroup(
      context,
      data: { id: string; permissionGroup: PermissionGroup }
    ) {
      return await api
        .put(`/v1/permission-group/${data.id}`, data.permissionGroup, {
          headers: { "vendor-module": "employees.Permissions" },
        })
        .then((response) => {
          context.commit("ADD_PERMISSION_GROUP", response.data.permissionGroup);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "PermissionGroup updated",
            },
            { root: true }
          );
          return response.data.permissionGroup;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async deletePermissionGroup(context, id) {
      return await api
        .delete(`/v1/permission-group${id}`, {
          headers: { "vendor-module": "employees.Permissions" },
        })
        .then((response) => {
          context.commit(
            "REMOVE_PERMISSION_GROUP",
            response.data.permissionGroup
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "PermissionGroup deleted",
            },
            { root: true }
          );
          return response.data.permissionGroup;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default permissionGroup;
