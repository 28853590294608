






































































































































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import { Business, Employee, Role } from "@/types";
import EmployeeWorkingHoursForm from "./EmployeeWorkingHoursForm.vue";
import employeeStoreModule from "@/store/modules/employee";
import jobTitleStoreModule from "@/store/modules/jobTitle";
import EmployeePermissionGroupList from "./EmployeePermissionGroupList.vue";
import { PHONE_REGEX } from "@/util/constants";
import EmployeeSerivceList from "../service/EmployeeSerivceList.vue";

const { mapActions: employeeActions } = createNamespacedHelpers("EMPLOYEE");

const { mapGetters: jobTitleGetters, mapActions: jobTitleActions } =
  createNamespacedHelpers("JOB_TITLE_LIST_EMPLOYEE_FORM");

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

export default Vue.extend<any, any, any, any>({
  components: {
    EmployeeWorkingHoursForm,
    EmployeePermissionGroupList,
    EmployeeSerivceList,
  },
  name: "EmployeeForm",
  data() {
    return {
      apiUrl: VUE_APP_API_URL,
      valid: false,
      allowBooking: true,
      showWorkingHoursDialog: false,
      fullName: "",
      phone: "",
      email: "",
      jobTitleId: "",
      nameRules: [(v: string) => !!v || "Name is required"],
      phoneRules: [
        (v: string) => !!v || "Phone is required",
        (v: string) =>
          PHONE_REGEX.test(v) || "A valid Phone number is required",
      ],
      emailRules: [
        (v: string) => !!v || "Email is required",
        (v: string) => /.+@.+/.test(v) || "Email must be valid",
      ],
      jobTitleRules: [(v: string) => !!v || "Job Title is required"],
      showAvatarDialog: false,
      image: undefined as undefined | File,
      commission: 0,
    };
  },
  props: {
    employeeId: {
      type: String,
      required: true,
    },
  },
  watch: {
    employee: "resetForm",
    role() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        this.fetchJobTitleList(`?businessId=${bid}`);
      }
    },
  },
  created() {
    this.fetchEmployee(`?employeeId=${this.employeeId}`);
  },
  computed: {
    ...jobTitleGetters(["jobTitlePage"]),
    employee(): Employee {
      return this.$store.getters["EMPLOYEE/getEmployee"](this.employeeId);
    },
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...employeeActions(["fetchEmployee", "updateEmployee"]),
    ...jobTitleActions(["fetchJobTitleList"]),
    validateForm() {
      const valid = (
        this.$refs.employeeForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;
      this.updateEmployee({
        id: this.employee._id,
        employee: {
          fullName: this.fullName,
          phone: this.phone,
          email: this.email,
          jobTitleId: this.jobTitleId,
          commissionRate: this.commission,
        },
      });
    },
    resetForm() {
      this.fullName = this.employee.fullName;
      this.phone = this.employee.phone;
      this.email = this.employee.email;
      this.jobTitleId = this.employee.jobTitle?._id;
      this.commission = this.employee.commissionRate;
      (
        this.$refs.postForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
    uploadFile(image: File) {
      this.image = image;
    },
    closeAvatarDialog() {
      this.showAvatarDialog = false;
      if (this.image) {
        const formData = new FormData();
        formData.append("file", this.image as File);

        this.updateEmployee({ id: this.employeeId, employee: formData }).then(
          (e) => {
            if (e) this.showAvatarDialog = false;
          }
        );
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("EMPLOYEE")) {
      this.$store.registerModule("EMPLOYEE", employeeStoreModule);
    }
    if (!this.$store.hasModule("JOB_TITLE_LIST_EMPLOYEE_FORM")) {
      this.$store.registerModule(
        "JOB_TITLE_LIST_EMPLOYEE_FORM",
        jobTitleStoreModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("EMPLOYEE");
    this.$store.unregisterModule("JOB_TITLE_LIST_EMPLOYEE_FORM");
  },
});
