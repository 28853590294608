var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"employee-permission-group-list"},[_c('v-dialog',{attrs:{"width":"440"},model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}},[(_vm.permission)?_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Confirm to delete permission ")]),_c('v-card-text',[_vm._v(" This action will delete "+_vm._s(((_vm.permission.employee.fullName) + "'s"))+" permission ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.showDeleteDialog = false}}},[_vm._v(" Disagree ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.deleteEmployeePermission(("/" + (_vm.permission._id)));
            _vm.showDeleteDialog = false;}}},[_vm._v(" Agree ")])],1)],1):_vm._e()],1),_c('v-dialog',{attrs:{"width":"440"},model:{value:(_vm.showAddDialog),callback:function ($$v) {_vm.showAddDialog=$$v},expression:"showAddDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Set permission ")]),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.permissionGroupPage.docs,"item-value":"_id","item-text":"name","label":"Permission Group"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" No permission groups were found ")]},proxy:true}]),model:{value:(_vm.permissionGroupId),callback:function ($$v) {_vm.permissionGroupId=$$v},expression:"permissionGroupId"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"disabled":!_vm.permissionGroupId,"color":"primary"},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.employeePermissionPage.docs,"options":_vm.options,"server-items-length":_vm.employeePermissionPage.total,"no-data-text":"No permissions to display"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.permissionGroup.name)+" ")]}},{key:"item.role",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.permissionGroup.role)+" ")]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"rounded":"","icon":"","x-large":"","color":"#F21501"},on:{"click":function($event){_vm.permission = item;
          _vm.showDeleteDialog = true;}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)]}}],null,true)}),(!_vm.employeePermissionPage.total)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.showAddDialog = true}}},[_vm._v(" Set Permission ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }