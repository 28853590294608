













































import Vue, { VueConstructor } from "vue";
import { createNamespacedHelpers } from "vuex";

import { Business, Role, Service } from "@/types";
import serviceStoreModule from "@/store/modules/service";

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

interface ServiceListClass {
  fetchServiceList(params: string): void;
  editService(service: Service): Service;
}

export default (Vue as VueConstructor<Vue & ServiceListClass>).extend<
  any,
  any,
  any,
  any
>({
  name: "EmployeeServiceForm",
  props: {
    employeeId: {
      type: String,
    },
  },
  data: () => ({
    service: undefined,
    apiUrl: VUE_APP_API_URL,
    headers: [
      {
        text: "Service",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Duration", value: "durationInMinutes" },
      { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
    selectedServices: [],
  }),
  created() {
    this.fetchServices();
  },
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
    servicesToAdd() {
      return this.servicePage.docs.filter(
        (item) => !item.performedBy.map((e) => e._id).includes(this.employeeId)
      );
    },
  },
  methods: {
    fetchServices() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        const params = `?businessId=${bid}&limit=${10000}`;
        this.fetchServiceList(params);
      }
    },
    addService(service: Service) {
      const index = this.selectedServices.findIndex(
        (item) => item._id === service._id
      );
      if (index > -1) {
        this.selectedServices.splice(index, 1);
      } else this.selectedServices.push(service);
    },
    save() {
      const payload = this.selectedServices.map((item) => ({
        serviceId: item._id,
        update: {
          performedBy: [...item.performedBy.map((e) => e._id), this.employeeId],
        },
      }));

      this.bulkUpdateServices({ data: payload }).then((services) => {
        if (services) this.$emit("saved", services);
      });
    },
  },
  beforeCreate() {
    const namespace = `SERVICE_LIST_$EMPLOYEE`;
    if (!this.$store.hasModule(namespace)) {
      this.$store.registerModule(namespace, serviceStoreModule);
    }
    const { mapGetters, mapActions } = createNamespacedHelpers(namespace);

    this.$options.computed = {
      ...(this.$options.computed || {}),
      ...mapGetters(["servicePage"]),
    };

    this.$options.methods = {
      ...(this.$options.methods || {}),
      ...mapActions([
        "fetchServiceList",
        "deleteService",
        "bulkUpdateServices",
      ]),
    };
  },
  beforeDestroy() {
    const namespace = `SERVICE_LIST_EMPLOYEE`;
    this.$store.unregisterModule(namespace);
  },
});
