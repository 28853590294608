

























































import Vue, { PropType } from "vue";

import { Employee } from "@/types";

export default Vue.extend<any, any, any, any>({
  name: "EmployeeWorkingHoursForm",
  data() {
    return {
      valid: false,
      startDate: "",
      endDate: "",
      description: "",
      startDateRules: [(v: string) => !!v || "Start Date is required"],
      endDateRules: [(v: string) => !!v || "End Date is required"],
      descriptionRules: [(v: string) => !!v || "Description is required"],
    };
  },
  props: {
    employee: {
      type: Object as PropType<Employee>,
      required: true,
    },
  },
  watch: {
    employee() {
      this.resetForm();
    },
  },
  mounted() {
    this.resetForm();
  },
  methods: {
    validateForm() {
      const valid = (
        this.$refs.workHourForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;
      // Logic for saving work hours
      this.$emit("data-saved", true);
    },
    resetForm() {
      this.startDate = "";
      this.endDate = "";
      this.description = "";
      (
        this.$refs.workHourForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
    cancel() {
      this.$emit("cancel", true);
    },
  },
});
