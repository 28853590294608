import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { EmployeePermission, Page } from "@/types";

type EmployeePermissionState = {
  employeePermissionPage: Page<EmployeePermission>;
};

const employeePermission: Module<EmployeePermissionState, unknown> = {
  namespaced: true,
  state: () => ({
    employeePermissionPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getEmployeePermission: (state) => (employeePermissionId: string) =>
      state.employeePermissionPage.docs.find(
        (ep) => ep._id === employeePermissionId
      ),
    employeePermissionPage: (state) => state.employeePermissionPage,
  },
  mutations: {
    ADD_EMPLOYEE_PERMISSION: (state, _employeePermission) => {
      let idx = -1;
      state.employeePermissionPage.docs.map((ep, i) => {
        if (ep._id === _employeePermission._id) idx = i;
      });
      if (idx === -1) {
        state.employeePermissionPage.docs.push(_employeePermission);
        state.employeePermissionPage.total += 1;
      } else
        Vue.set(state.employeePermissionPage.docs, idx, _employeePermission);
    },
    SET_EMPLOYEE_PERMISSION_PAGE: (state, list) => {
      state.employeePermissionPage = list;
    },
    REMOVE_EMPLOYEE_PERMISSION(state, employeePermission) {
      let idx = -1;
      state.employeePermissionPage.docs.map((r, i) => {
        if (r._id === employeePermission._id) idx = i;
      });
      if (idx > -1) state.employeePermissionPage.docs.splice(idx, 1);
    },
  },
  actions: {
    async fetchEmployeePermission(context, params = "") {
      return await api
        .get(`/v1/permission/employee${params}`)
        .then((response) => {
          context.commit(
            "ADD_EMPLOYEE_PERMISSION",
            response.data.employeePermission
          );
          return response.data.employeePermission;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchEmployeePermissionList(context, params = "") {
      return await api
        .get(`/v1/permission/employee${params}`)
        .then((response) => {
          context.commit(
            "SET_EMPLOYEE_PERMISSION_PAGE",
            response.data.employeePermissionPage
          );
          return response.data.employeePermissionPage;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createEmployeePermission(context, payload) {
      return await api
        .post(`/v1/permission/employee`, payload, {
          headers: { "vendor-module": "employees.Permissions" },
        })
        .then((response) => {
          context.commit(
            "ADD_EMPLOYEE_PERMISSION",
            response.data.employeePermission
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "EmployeePermission created",
            },
            { root: true }
          );
          return response.data.employeePermission;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    updateEmployeePermission(
      context,
      data: { id: string; employeePermission: EmployeePermission }
    ) {
      api
        .put(`/v1/permission/employee/${data.id}`, data.employeePermission, {
          headers: { "vendor-module": "employees.Permissions" },
        })
        .then((response) => {
          context.commit(
            "ADD_EMPLOYEE_PERMISSION",
            response.data.employeePermission
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "EmployeePermission updated",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async bulkCreateEmployeePermission(context, payload) {
      return await api
        .post(`/v1/permission/employee/bulk`, payload, {
          headers: { "vendor-module": "employees.Permissions" },
        })
        .then((response) => {
          response.data.employeePermissions.map((e: EmployeePermission) => {
            context.commit("ADD_EMPLOYEE_PERMISSION", e);
          });
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "EmployeePermission created",
            },
            { root: true }
          );
          return response.data.employeePermissions;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async deleteEmployeePermission(context, id) {
      return await api
        .delete(`/v1/permission/employee${id}`, {
          headers: { "vendor-module": "employees.Permissions" },
        })
        .then((response) => {
          context.commit(
            "REMOVE_EMPLOYEE_PERMISSION",
            response.data.employeePermission
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "EmployeePermission deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default employeePermission;
